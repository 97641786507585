
import { defineComponent } from 'vue'

import FooterUT from '@/components/molecules/Footer.vue'

export default defineComponent({
  name: 'HowItWorksSection',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    FooterUT
  },
  methods: {
  }
})
